<template>
  <div>
    <h2 class="text-xl font-bold">
      Sitemap
    </h2>
    <div class="my-7 grid grid-flow-row gap-1">
      <NuxtLink to="/blog" class="prettyLink max-w-fit">
        Blog
      </NuxtLink>
      <NuxtLink to="/workshops" class="prettyLink max-w-fit">
        Workshops
      </NuxtLink>
      <NuxtLink to="/courses" class="prettyLink max-w-fit">
        Courses
      </NuxtLink>
      <NuxtLink to="/speaking-policy" class="prettyLink max-w-fit">
        Speaking policy
      </NuxtLink>
      <NuxtLink to="/setting-up-trelloapp" class="prettyLink max-w-fit">
        Trello app installation
      </NuxtLink>
      <NuxtLink to="/privacy-policy" class="prettyLink max-w-fit">
        Privacy policy
      </NuxtLink>
      <NuxtLink to="/terms-of-service" class="prettyLink max-w-fit">
        Terms of service
      </NuxtLink>
      <NuxtLink to="/newsletter" class="prettyLink max-w-fit">
        Newsletter
      </NuxtLink>
    </div>
  </div>
</template>
